import React from "react";
import { Box, Grid, Typography, Button, Paper } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";

const OtrasOpcionesJubilacionCard = ({ title, description, img, buttonLabel, onClick, disabled }) => {
    return (
        <Box className="otras-opciones-card">
            <Grid container alignItems="center">
                {/* Descripción */}
                <Grid item xs={12} sm={4} className="otras-opciones-description">
                    <Typography className="description-text" component="p">
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                    </Typography>
                </Grid>
                {/* Botón e Imagen */}
                <Grid item xs={12} sm={8} className="otras-opciones-button-container">
                    <Paper elevation={3} className="button-wrapper">
                        <Box className="button-content">
                            <Box className="button-img-container">
                                <img src={img} alt={title} className="button-img" />
                                <p className="button-title">{title}</p>
                            </Box>
                            <Button
                                className="action-button"
                                onClick={onClick}
                                disabled={disabled}
                                endIcon={<ArrowForwardIosIcon />}
                            >
                                {buttonLabel}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OtrasOpcionesJubilacionCard;
