import React, { useRef, forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { string, oneOfType, object, bool, func } from "prop-types";
import { useFormContext } from "react-hook-form";
import { CaptchaInputComponent } from "./styles";

const CaptchaInput = forwardRef((props, ref) => {
  const { name, validate, required, onChange, onChange2, ...restProps } = props;
  const captchaRef = useRef(null);

  const { register, setValue, trigger } = useFormContext();
  register(name, { required, validate });

  const onChangeHandler = (e) => {
    onChange2(e);
    setValue(name, e);
    trigger(name);
  };

  const selectOnChange = onChange || onChangeHandler;

  return (
    <CaptchaInputComponent>
      <ReCAPTCHA
        ref={(el) => {
          captchaRef.current = el;
          if (ref) ref.current = el; // Pasa la referencia al componente padre
        }}
        sitekey="6LfH3XkmAAAAAAZ0C6jfmlhKRjVuO1VFvKt9-pNA"
        onChange={selectOnChange}
        {...restProps}
      />
    </CaptchaInputComponent>
  );
});

export default CaptchaInput;

CaptchaInput.propTypes = {
  name: string,
  required: oneOfType([bool, object]),
  validate: object,
  onChange: func,
};

CaptchaInput.defaultProps = {
  name: "captcha-input",
  required: {
    value: true,
    message: "El captcha es requerido.",
  },
};
