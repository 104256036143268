import React from "react";
import "./EsperaEstimacionCard.css"; // Archivo CSS para estilos personalizados
import MessageSnack from "../../../../components/MessageSnack";
import ICON_INFO from "../../../../assets/icons/info_circle.svg";

const EsperaEstimacionCard = (props) => {
    const {
        showScreen,
        title,
        description,
        btnLabel,
        btnFunction,
        loading,
        disable,
        note,
        hasAdvice,
        nota
    } = props;

    const renderIcon = () => {
        if (loading) {
            return (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            );
        } else return null;
    };

    const renderNoteMessage = () => {
        if (nota === 'CONTACT') {
            return (
                <MessageSnack icon={ICON_INFO} alt="ICON_INFO" marginTop="1.2em">
                    <ul className="note-list">
                        <li className="note-item">
                            Si prefieres, contáctanos a través de nuestros{" "}
                            <a
                                href="https://www.afphabitat.com.pe/canales-de-atencion/"
                                className="note-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                canales de atención al cliente
                            </a>.
                        </li>
                    </ul>
                </MessageSnack>
            );
        }

        if (nota === 'SPAM') {
            return (
                <MessageSnack icon={ICON_INFO} alt="ICON_INFO" marginTop="1.2em">
                    <ul className="note-list">
                        <li className="note-item">
                            No olvides revisar también tu carpeta de SPAM.
                        </li>
                    </ul>
                </MessageSnack>
            );
        }

        return null;
    };

    return (
        showScreen && (
            <div className="espera-estimacion-container">
                <div className="espera-estimacion-card">
                    <div className="espera-estimacion-header">
                        <h3>{title}</h3>
                    </div>
                    <div className="espera-estimacion-body">
                        <p>{description}</p>
                    </div>
                    {note && (
                        <div className="espera-estimacion-note">
                            <span>{note}</span>
                        </div>
                    )}
                    {hasAdvice && renderNoteMessage()}
                    <div className="espera-estimacion-footer">
                        <button
                            className="btn-modal-primary"
                            onClick={btnFunction}
                            disabled={disable}
                        >
                            {btnLabel} {renderIcon()}
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default EsperaEstimacionCard;
