import { useEffect } from "react";

export default function FixCookieDialogStyles() {
    useEffect(() => {
        const applyStyles = () => {
            const dialog = document.querySelector(".CybotCookiebotDialogActive");

            if (dialog) {
                if (window.innerWidth <= 768) {
                    // Reaplicar los estilos cada vez que cambian las clases
                    dialog.style.setProperty("height", "45%", "important");
                    dialog.style.setProperty("top", "75%", "important");
                } else {
                    // En desktop, remover los estilos
                    dialog.style.removeProperty("height");
                    dialog.style.removeProperty("top");
                }
            }
        };

        // Ejecutar inmediatamente si el div ya existe
        applyStyles();

        // Usar MutationObserver para detectar cambios en las clases del div
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === "class") {
                    applyStyles(); // Aplicar estilos nuevamente si cambia la clase
                }
            });
        });

        const watchForChanges = () => {
            const dialog = document.querySelector(".CybotCookiebotDialogActive");
            if (dialog) {
                observer.observe(dialog, { attributes: true, attributeFilter: ["class"] });
            }
        };

        watchForChanges(); // Observar cambios en el div si ya existe

        // Observar cambios en el body para detectar la aparición del div
        const bodyObserver = new MutationObserver(() => {
            watchForChanges();
            applyStyles();
        });
        bodyObserver.observe(document.body, { childList: true, subtree: true });

        // Agregar un event listener para detectar cambios en el tamaño de la ventana
        window.addEventListener("resize", applyStyles);

        return () => {
            observer.disconnect();
            bodyObserver.disconnect();
            window.removeEventListener("resize", applyStyles);
        };
    }, []);

    return null;
}
