import React from "react";
import "./index.css";

const TraspasoWidget = () => {
  const goToUrl = (url) => {
    window.open(url, "_self").focus();
  };

  return (
    <div className="bottom-widget-container">
      <div
        className="btn-widget clic_button_home_sticky"
        onClick={() =>
          goToUrl(
            "https://cambiate.afphabitat.com.pe/identidad?utm_source=home&utm_medium=menu&utm_campaign=traspasos"
          )
        }
      >
        <span className="option-desktop-title">Cámbiate a HABITAT</span>
      </div>
    </div>
  );
};

export default TraspasoWidget;
