import React from "react";
import Carousel from "react-elastic-carousel";
import Prudential from "../../../../assets/img/Prudential.png";
import InversionesLaConstruccion from "../../../../assets/img/inversiones-la-construccion.png";
import Rentabilidad from "../../../../assets/img/rentabilidad.png";
import Premio from "../../../../assets/img/Sello-BCX.png";
import Merco from "../../../../assets/img/Merco.png";
import MercoESG from "../../../../assets/img/merco_esg.png";
import "./index.css";

const Section4 = () => {
  // const breakPoints = [
  //     { width: 1, itemsToShow: 1 },
  //     { width: 850, itemsToShow: 3, pagination: false }
  // ];

  return (
    <section className="section-content section4">
      <div className="container-new-home">
        <Carousel itemsToShow={1} itemPadding={[0, 15]} showArrows={false}>
          <div className="margin-carousel">
            <h2 className="mb-4 h2-new-home">Sólido respaldo internacional</h2>
            <p className="text-description text-center margin-description">
              Contamos con el respaldo de importantes grupos financieros
            </p>
            <br />

            <div className="cards-content">
              <div className="card-border card-carousel col-new-home odd">
                <img src={Prudential} alt="Prudential" draggable={false} />
                <p>
                  Más de 149 años de experiencia en inversiones, seguros de vida
                  y pensiones. Presencia en América, Asia y Europa.
                </p>
              </div>

              <div className="card-border card-carousel col-new-home pair">
                <img
                  src={InversionesLaConstruccion}
                  alt="Inversiones-La-Construccion"
                  draggable={false}
                />
                <p>
                  Es el conglomerado de inversionistas mas grande de Chile y
                  cuenta con inversiones en diferentes sectores económicos.
                </p>
              </div>
            </div>
          </div>

          <div>
            <h2 className="mb-4 h2-new-home">Excelente servicio previsional</h2>
            <p className="text-description text-center margin-description">
              Estamos comprometidos a ofrecer la mejor atención y la mayor
              rentabilidad a nuestros afiliados.
            </p>
            <br />

            <div className="cards-content">
              <div className="card-border card-carousel col-new-home odd">
                <img src={Rentabilidad} alt="Rentabilidad" draggable={false} />
                <p>
                  Perú Golden Awards nos reconoció como el Mejor Gestor de
                  Inversiones por 8 años consecutivos.
                </p>
              </div>

              <div className="card-border card-carousel row-new-home pair">
                <img
                  src={Premio}
                  alt="Premio"
                  draggable={false}
                  style={{ width: "71px" }}
                />
                <p>
                  Reconocidos por quinto año consecutivo como la AFP con mejor
                  Experiencia al Cliente.
                </p>
              </div>
            </div>
          </div>

          <div>
            <h2 className="mb-4 h2-new-home">Reconocida imagen empresarial</h2>
            <p className="text-description text-center margin-description">
              Reconocidos por trabajar día a día por nuestros grupos de interés:
              afiliados, colaboradores y sociedad.
            </p>
            <br />

            <div className="cards-content">
              <div className="card-border card-carousel col-new-home odd">
                <img src={Merco} alt="Merco" draggable={false} />
                <p>
                  Somos parte del Top 100 de las Empresas con Mejor
                  Reputación Corporativa en Perú, según el ranking Merco Empresas 2024
                </p>
              </div>

              <div className="card-border card-carousel col-new-home odd">
                <img src={MercoESG} alt="MercoESG" draggable={false} />
                <p>
                  Somos parte del ranking Merco, que reconoce
                  a las 100 empresas más responsables del país.
                </p>
              </div>
            </div>
          </div>
        </Carousel>

        <button
          className="block btn-primary-new-home margin-center mt-4"
          onClick={() => {
            window
              .open("https://www.afphabitat.com.pe/nosotros/", "_self")
              .focus();
          }}
        >
          Conoce más de HABITAT
        </button>
      </div>
    </section>
  );
};

export default Section4;
