import React, { useState, useEffect, useContext } from "react";
import { Estimacion, EstimacionDetalle, EstimacionAlerta } from "./components";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";
import moment from "moment";
// Nuevo componente de vista espera para Estimacion
import EsperaEstimacionCard from "../../../../Components/EsperaEstimacionCard/EsperaEstimacionCard";

export default function Paso4(props) {
  const [showView, setShowView] = useState(0);
  const [detailData, setDetailData] = useState({ percent: "", object: null });
  const [contents, setContents] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    estimacionRejaData,
    estimacionPDFRejaLoading,
    guardarPaso4Response,
    obtenerEstimacionRejaData,
    obtenerContenidoPasoReja,
    limpiarObtenerEstimacionRejaData,
    limpiarObtenerContenidoPasoReja,
    temasAsesoriaRejaData,
    limpiarObtenerPDFEstimacionRejaData,
    resetear,
  } = jubilacionContext;

  useEffect(() => {
    props.setCurrectStepTitle("Estimación de pensión");
    limpiarObtenerEstimacionRejaData();// Limpia los datos de estimacionRejaData antes de hacer la solicitud
    obtenerContenidoPasoReja(props.processId, 4);

    return () => {
      limpiarObtenerContenidoPasoReja();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);

  useEffect(() => {
    if (temasAsesoriaRejaData) {
      if (temasAsesoriaRejaData.success) {
        if (temasAsesoriaRejaData.step === 4) {
          props.setCurrectStepTitle(temasAsesoriaRejaData.name);
          setContents(temasAsesoriaRejaData.contents);
          obtenerEstimacionRejaData(props.processId);
        }
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temasAsesoriaRejaData]);
  useEffect(() => {
    if (estimacionRejaData) {
      console.log(estimacionRejaData);
      console.log('llego rejaData');


      if (estimacionRejaData.r_validacion === 0) {
        setShowView(1);
      } else {
        setShowView(2);
      }
    }
  }, [estimacionRejaData]);
  useEffect(() => {
    if (guardarPaso4Response) {
      if (guardarPaso4Response.success) {
        if (guardarPaso4Response.result === 0) {
          setShowView(4);
          limpiarObtenerPDFEstimacionRejaData();
          limpiarObtenerContenidoPasoReja();
        } else {
          props.setCurrectStepNumber(5);
        }
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardarPaso4Response]);

  const isEstimacionRejaDataEmpty = !estimacionRejaData || estimacionRejaData.r_validacion === undefined;

  const renderView = () => {
    if (isEstimacionRejaDataEmpty) {
      return <Loading />; // Mostrar Loading si los datos aún no están disponibles
    }

    const nota = estimacionRejaData.data?.r_indicador || null;

    switch (showView) {
      case 1:
        return (
          <EsperaEstimacionCard
            showScreen={true} // Controlado por el estado
            className="beauty-modal"
            title="Toma de decisión"
            description={
              <div dangerouslySetInnerHTML={{ __html: estimacionRejaData.message }} />
            }
            btnFunction={() => {
              props.getOutOfProcess();
            }}
            btnLabel={"Entendido"}
            hasAdvice={!!nota}
            nota={nota}
          />
        );
      case 2:
        return (
          <Estimacion
            data={estimacionRejaData.data}
            dateDecision={estimacionRejaData.data.decision_date}
            setDetail={setDetailData}
            idContent={contents.length === 0 ? 0 : contents[0].id}
            endFifthStep={() => props.setCurrectStepNumber(5)}
            showDetail={() => setShowView(3)}
            showWaitView={() => setShowView(4)}
            processId={props.processId}
            estimacionPDFRejaLoading={estimacionPDFRejaLoading}
          />
        );
      case 3:
        return (
          <EstimacionDetalle
            detailData={detailData}
            setDetail={setDetailData}
            getBackToMain={() => setShowView(2)}
          />
        );
      case 4:
        return (
          <EstimacionAlerta
            error={guardarPaso4Response}
            showDetail={() => {
              setShowView(2);
              resetear();
            }}
          />
        );
      default:
        return <Loading />;
    }
  };
  return (
    <div className="af-content-estimacion">
      {renderView()} {showModal && <ModalDeErrorGeneral />}
    </div>
  );
}
